exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-brand-of-the-month-js": () => import("./../../../src/pages/brand-of-the-month.js" /* webpackChunkName: "component---src-pages-brand-of-the-month-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-brand-of-the-month-js": () => import("./../../../src/templates/brandOfTheMonth.js" /* webpackChunkName: "component---src-templates-brand-of-the-month-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/caseStudies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-tags-template-js": () => import("./../../../src/templates/tags-template.js" /* webpackChunkName: "component---src-templates-tags-template-js" */),
  "component---src-templates-term-of-use-js": () => import("./../../../src/templates/termOfUse.js" /* webpackChunkName: "component---src-templates-term-of-use-js" */)
}

